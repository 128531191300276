import EInvoicingConfiguration from "./config.type";

export const Configuration: EInvoicingConfiguration = {
    msalConfig: {
        auth: {
            clientId: "36af44e5-21a2-4ba0-b039-b76ef8b6b0e8",
            authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
            redirectUri: "https://modern.einvoicing-dashboard.cp.microsoft.com", //http://localhost:3000 
        },
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        }
    },
    
    // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
    msalTokenRequest: {
        scopes: []
    },

    eventServiceUrl: "https://modern.einvoicing.eventservice.cp.microsoft.com"
}
